<template>
  <!-- 教练团队 -->
  <div class="jy_coach">
    <div class="jy_coach_bg">
      <img :src="region_bg" alt="" style="width:100%" />
    </div>
    <div class="jy_coach_nav">
      <ul class="jy_coach_list" v-if="Object.keys(jlData).length != 0">
        <li v-for="(item, idx) in jlData.jl_list" :key="idx">
          <div class="list_image">
            <img :src="item.value" alt="" style="width:100%;height: 100%;" />
            <div class="all_mark">
              <span>——</span>
              <div class="mark_div two_ellipsis">{{ item.key }}</div>
              <span>——</span>
            </div>
          </div>
          <div class="list_title ellipsis">
            <h2>{{ item.key }}</h2>
            <p>{{ jlData.jl_list2[idx].value }}</p>
          </div>
        </li>
      </ul>
      <div class="details_empty" v-else>
        <img
          src="@/assets/image/xwdt/xwdt-data.webp"
          alt=""
          style="margin:0 auto"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { getPageMsg } from '@/api/api'
export default {
  components: {},
  data () {
    return {
      region_bg: require('@/assets/image/all/jy_bg.png'),
      jlData: {}
    }
  },
  watch: {},
  methods: {
    getData () {
      let that = this
      let id = sessionStorage.getItem(that.changeData() + 'id')
      getPageMsg(id).then(res => {
        if (res.data.code == 1) {
          console.log(res.data.data)
          that.jlData = res.data.data.教练
        }
      })
    }
  },
  mounted () {
    this.getData()
  }
}
</script>
<style lang="less" scoped>
@import url('~@/assets/css/mixins.less'); //混合
.jy_coach {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #f1f1f1;
  .jy_coach_bg {
    width: 100%;
    overflow: hidden;
    position: relative;
    img {
      animation: scaleAnim 3s ease forwards;
    }
  }
  .jy_coach_nav {
    margin: 1rem 0;
  }
  .jy_coach_list,
  .details_empty {
    width: 15.2rem;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    position: relative;
    li {
      padding: 0.1rem;
      display: inline-block;
      font-size: 0.18rem;
      vertical-align: middle;
      width: calc(50% - 0.2rem);
      cursor: pointer;
      .list_image {
        width: 100%;
        height: auto;
        position: relative;
      }
      .list_title {
        padding: 0.2rem;
        background-color: #fff;
        line-height: 1.5;
        h2{
            font-size: 0.25rem;
        }
        p {
            white-space: wrap;
        }
        h2:hover {
          color: #0a8ce2;
        }
      }
    }
    li:hover {
      .all_mark {
        height: 100%;
      }
    }
  }
  .details_empty {
    width: 15rem;
    background-color: #fff;
  }
}
@keyframes scaleAnim {
  0% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
</style>
